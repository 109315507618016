.estimate-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 607px);
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: lightgrey;
}

.estimate-content {
    display: flex;
    width: 980px;
    height: 100%;
}

.estimate-description-wrapper {
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-bottom: auto;
    padding-right: 30px;
}

.estimate-description-title {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 1.75em;
}

.estimate-description {
    width: 625px;
    margin: 0;
    padding: 0;
    font-size: 1.25em;
    line-height: 30px;
}

.estimate-form {
    display: flex;
    flex-direction: column;
    width: 325px;
    padding-left: 30px;
}

.form-item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-submit {
    background-color: grey;
    border: black;
    color: white;
}

@media only screen and (max-width: 1140px){
    .estimate-content {
        flex-direction: column;
        width: 350px;
        align-items: left;
        padding-left: 30px;
        padding-right: 30px;
    }

    .estimate-description {
        width: 350px;
    }

    .estimate-form {
        padding-top: 30px;
        padding-left: 0;
    }
}
