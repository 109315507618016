.about-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
    min-height: calc(100vh - 567px);
    background-color: lightgrey;
}

.about-content-title {
    margin-top: 15px;
    font-size: 2em;
}

.about-content-paragraph {
    margin-top: 15px;
    font-size: 1.5em;
    text-align: left;
}

.about-content-list {
    margin: 0;
    padding: 0;
    margin-left: 30px;
    list-style-type: none;
}

.about-list-title {
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.about-list-item {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-size: .8em
}

.locations {
    margin-bottom: 15px;
}
