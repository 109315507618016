.footer-wrapper{
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 185px;
    color: white;
}

.pre-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(58, 58, 58);
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 170px;
}

.left-contents, .right-contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 40vw;
    min-height: 150px;
}

.center-contents {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20vw;
    min-height: 150px;
}

.contact, .estimate, .business-hours, .services{
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    margin: 5px;
    min-height: 75px;
}

.pre-footer-content{
    margin: 0;
    padding: 0;
    font-size: .85em;
    text-align: center;
}

.footer-logo {
    margin-top: 15px; 
}

.slogan {
    margin-top: 7px;
    text-align: center;
}

.pre-footer-link {
    text-decoration: none;
    color: white;
}

.pre-footer-list {
    list-style-type: none;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 0;
    margin: 0;
    min-height: 35px;
    width: 100vw;
}

.copyright {
    text-align: center;
    border: solid black .5px;
}

@media only screen and (max-width: 670px){
    .left-contents, .right-contents {
        display: none;
    }
    .center-contents {
        width: 100vw;
    }
}
