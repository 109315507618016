.slideshow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    background-color: lightgrey;
}

.transition-wrapper-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
}

.slideshow-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    transition: ease 2s;
}

.transition-wrapper-text {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    position: absolute;
}

.slideshow-img-text {
    width: 325px;
    font-family: arial;
    font-size: 2em;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
    transition: ease 2s;
    transition-delay: 3s;
}

.btn-next {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 0;
}

.btn-prev {
    position: absolute;
    background-color: transparent;
    border: none;
    left: 0;
}

.enter {
    opacity: 0;
}

.exit {
    display: none;
}

.enter-done {    
}

.hidden {
    display: none;
}

@media only screen and (min-width: 1140px){
    .slideshow-img-text {
        width: 600px;
        font-size: 3em
    }
}

.strip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    background-color: black;
}

.strip-text {
    color: white; 
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
}

@media only screen and (max-width: 1140px){
    .strip-text {
        font-size: 1em;
    }    
}

.services-preview-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 905px);
    background-color: lightgrey;
    border: solid 1px;
}

.services-preview-item{
    position: relative;
    width: 280px;
    height: 400px;
    background-color: rgb(33, 33, 33);
    border: solid 1px;
    margin: 60px;
    padding-left: 13.5px;
    padding-right: 13.5px;
}

.services-preview-img-wrapper {
    width: 275px;
    height: 165px;
    border: solid 1px;
    top: 0;
    margin-top: 12.5px;
    margin-left: auto;
    margin-right: auto;
    border: solid white 1px;
}

.services-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; 
}

.services-preview-title {
    color: white;
    margin-top: 10px;
    text-align: center;
    font-size: 1.5em;
}

.services-preview-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 125px;
    height: 35px;
    background-color: lightgrey;
    border: solid white 1px;
    color: black;
    font-size: 1.5em;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 335px;
    margin-left: auto;
    margin-right: auto;
}

.review-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: black;
    z-index: -2;
}

.review-img {
    position: absolute;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center; 
    z-index: -1;
}

.review-text {
    width: 325px;
    font-family: arial;
    font-size: 1.5em;
    -webkit-text-stroke: 1px black;
    color: white;
    text-align: center; 
}

.review-icons {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
}

.services-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 597px);
    background-color: lightgrey;
}

.services-content-item {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 250px;
    height: 350px;
    background-color: rgb(33, 33, 33);
    border: solid 1px;
    margin: 30px;
    z-index: 0
}

.services-content-img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 150px;
    margin-top: 12.5px;
    background-color: white;
    z-index: 1;
}

.services-content-img {
    object-fit: cover;
    object-position: center;
}

.services-content-title {
    position: absolute;
    width: 225px;
    margin-top: 180px;
    font-size: 1.5em;
    text-align: center;
    color: white;
}

.services-content-description {
    position: absolute;
    width: 225px;
    margin-top: 225px;
    align-text: center;
    color: white;
}

.services-content-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    bottom: 0;
    margin-bottom: 30px;
    background-color: lightgrey;
    color: black;
    font-size: 2em;
    text-decoration: none;
    text-align: center;
    border: solid white 1px;
}

.services-content-btn-text {
    font-size: .85em;
    margin: 0;
    padding: 0;
    text-align: center;
}

.services-content-btn:hover {
    background-color: white;
    transition: .5s ease;
}

.services-content-src {
    width: 105px;
    height: 10px;
    margin-top: 150px;
    margin-left: 120px;
    color: white;
    font-size: .60em;
    font-family: arial;
    font-weight: normal;
    text-decoration: none;
    z-index: 2;
}

.license {
    color: white;
    font-family: arial;
    font-weight: normal;
    text-decoration: none;
}

.head-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.head-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -999;
}

.head-img-description {
    max-width: 900px;
    color: white;
    font-family: arial;
    font-size: 3em;
    text-align: center;
    -webkit-text-stroke: 1.5px black;
}

@media only screen and (max-width: 1140px){
    .head-img-description {
        max-width: 500px; 
        font-size: 2em;
    }
}

.about-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.about-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

.about-img-description {
    font-size: 3em;
    font-family: arial;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
}

.about-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
    min-height: calc(100vh - 567px);
    background-color: lightgrey;
}

.about-content-title {
    margin-top: 15px;
    font-size: 2em;
}

.about-content-paragraph {
    margin-top: 15px;
    font-size: 1.5em;
    text-align: left;
}

.about-content-list {
    margin: 0;
    padding: 0;
    margin-left: 30px;
    list-style-type: none;
}

.about-list-title {
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.about-list-item {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-size: .8em
}

.locations {
    margin-bottom: 15px;
}

.gallery-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.gallery-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

.gallery-img-description {
    font-size: 3em;
    font-family: arial;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
}

.gallery-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 567px);
    background-color: lightgrey;
}

.gallery-content-img-wrapper {
    width: 300px;
    height: 300px;
    margin: 30px;
    border: solid black 1px;
}

.gallery-content-img {
    object-fit: cover;
    object-position: center;
}

.estimate-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    border: solid 1px;
}

.estimate-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

.estimate-img-description {
    font-size: 3em;
    font-family: arial;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
}

.estimate-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 607px);
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: lightgrey;
}

.estimate-content {
    display: flex;
    width: 980px;
    height: 100%;
}

.estimate-description-wrapper {
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-bottom: auto;
    padding-right: 30px;
}

.estimate-description-title {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 1.75em;
}

.estimate-description {
    width: 625px;
    margin: 0;
    padding: 0;
    font-size: 1.25em;
    line-height: 30px;
}

.estimate-form {
    display: flex;
    flex-direction: column;
    width: 325px;
    padding-left: 30px;
}

.form-item {
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-submit {
    background-color: grey;
    border: black;
    color: white;
}

@media only screen and (max-width: 1140px){
    .estimate-content {
        flex-direction: column;
        width: 350px;
        align-items: left;
        padding-left: 30px;
        padding-right: 30px;
    }

    .estimate-description {
        width: 350px;
    }

    .estimate-form {
        padding-top: 30px;
        padding-left: 0;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 0;
    margin: 0;
    height: 25px;
}

.header-number {
    color: white;
    margin-right: auto;
    margin-left: 25px;
    padding: 1px;
}

.header-estimate {
    color: white;
    margin-left: auto;
    margin-right: 25px;
    padding: 1px;
}

@media only screen and (max-width: 300px){
    .header {
        display: none;
    } 
}

.footer-wrapper{
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 185px;
    color: white;
}

.pre-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(58, 58, 58);
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 170px;
}

.left-contents, .right-contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 40vw;
    min-height: 150px;
}

.center-contents {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20vw;
    min-height: 150px;
}

.contact, .estimate, .business-hours, .services{
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    margin: 5px;
    min-height: 75px;
}

.pre-footer-content{
    margin: 0;
    padding: 0;
    font-size: .85em;
    text-align: center;
}

.footer-logo {
    margin-top: 15px; 
}

.slogan {
    margin-top: 7px;
    text-align: center;
}

.pre-footer-link {
    text-decoration: none;
    color: white;
}

.pre-footer-list {
    list-style-type: none;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 0;
    margin: 0;
    min-height: 35px;
    width: 100vw;
}

.copyright {
    text-align: center;
    border: solid black .5px;
}

@media only screen and (max-width: 670px){
    .left-contents, .right-contents {
        display: none;
    }
    .center-contents {
        width: 100vw;
    }
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(58, 58, 58);
    padding: 0;
    margin: 0;
    height: 85px;
    border: solid .5px;
}

.navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.navbar-links:hover {
    background-color: rgb(150, 150, 150);
    color: black;
    transition: .5s ease;
}

.logo-wrapper {
    position: absolute;
    width: 150px;
    height: 75px;
    left: 0;
    margin-left: 25px;
}

.logo {
    width: 100%;
    height: 100%;
}

.bars-menu {
    display: none;
    flex-direction: column;
    background-color: grey;
    height: 300px;
    width: 100vw;
    box-sizing: border-box;
}

.bars-links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    color: white;
    border: solid darkgrey .5px;
    height: 100%;
    width: 100%;
}

.bars-links:hover {
    background-color: rgb(150, 150, 150);
    color: black;
    transition: .5s ease;
}

.bars-icon {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 25px;
    border: none;
    padding: none;
    background-color: transparent;
}

.exit-icon {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 25px;
    border: none;
    padding: none;
    background-color: transparent;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 1140px){
    .navbar-links {
        display: none;
    }
    .bars-menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bars-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

* {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

#root {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%; 
}

.app {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%; 
}

.content {
    padding: 0;
    margin: 0;
    min-height: calc(100vh - 317px);
    width: 100%;
}

