.head-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.head-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -999;
}

.head-img-description {
    max-width: 900px;
    color: white;
    font-family: arial;
    font-size: 3em;
    text-align: center;
    -webkit-text-stroke: 1.5px black;
}

@media only screen and (max-width: 1140px){
    .head-img-description {
        max-width: 500px; 
        font-size: 2em;
    }
}
