.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(58, 58, 58);
    padding: 0;
    margin: 0;
    height: 85px;
    border: solid .5px;
}

.navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.navbar-links:hover {
    background-color: rgb(150, 150, 150);
    color: black;
    transition: .5s ease;
}

.logo-wrapper {
    position: absolute;
    width: 150px;
    height: 75px;
    left: 0;
    margin-left: 25px;
}

.logo {
    width: 100%;
    height: 100%;
}

.bars-menu {
    display: none;
    flex-direction: column;
    background-color: grey;
    height: 300px;
    width: 100vw;
    box-sizing: border-box;
}

.bars-links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    color: white;
    border: solid darkgrey .5px;
    height: 100%;
    width: 100%;
}

.bars-links:hover {
    background-color: rgb(150, 150, 150);
    color: black;
    transition: .5s ease;
}

.bars-icon {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 25px;
    border: none;
    padding: none;
    background-color: transparent;
}

.exit-icon {
    display: none;
    position: absolute;
    right: 0;
    margin-right: 25px;
    border: none;
    padding: none;
    background-color: transparent;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 1140px){
    .navbar-links {
        display: none;
    }
    .bars-menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bars-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
