.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 0;
    margin: 0;
    height: 25px;
}

.header-number {
    color: white;
    margin-right: auto;
    margin-left: 25px;
    padding: 1px;
}

.header-estimate {
    color: white;
    margin-left: auto;
    margin-right: 25px;
    padding: 1px;
}

@media only screen and (max-width: 300px){
    .header {
        display: none;
    } 
}
