* {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

#root {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%; 
}

.app {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%; 
}

.content {
    padding: 0;
    margin: 0;
    min-height: calc(100vh - 317px);
    width: 100%;
}
