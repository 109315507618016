.slideshow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    background-color: lightgrey;
}

.transition-wrapper-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
}

.slideshow-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    transition: ease 2s;
}

.transition-wrapper-text {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    position: absolute;
}

.slideshow-img-text {
    width: 325px;
    font-family: arial;
    font-size: 2em;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
    transition: ease 2s;
    transition-delay: 3s;
}

.btn-next {
    position: absolute;
    background-color: transparent;
    border: none;
    right: 0;
}

.btn-prev {
    position: absolute;
    background-color: transparent;
    border: none;
    left: 0;
}

.enter {
    opacity: 0;
}

.exit {
    display: none;
}

.enter-done {    
}

.hidden {
    display: none;
}

@media only screen and (min-width: 1140px){
    .slideshow-img-text {
        width: 600px;
        font-size: 3em
    }
}
