.services-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 597px);
    background-color: lightgrey;
}

.services-content-item {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 250px;
    height: 350px;
    background-color: rgb(33, 33, 33);
    border: solid 1px;
    margin: 30px;
    z-index: 0
}

.services-content-img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 150px;
    margin-top: 12.5px;
    background-color: white;
    z-index: 1;
}

.services-content-img {
    object-fit: cover;
    object-position: center;
}

.services-content-title {
    position: absolute;
    width: 225px;
    margin-top: 180px;
    font-size: 1.5em;
    text-align: center;
    color: white;
}

.services-content-description {
    position: absolute;
    width: 225px;
    margin-top: 225px;
    align-text: center;
    color: white;
}

.services-content-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    bottom: 0;
    margin-bottom: 30px;
    background-color: lightgrey;
    color: black;
    font-size: 2em;
    text-decoration: none;
    text-align: center;
    border: solid white 1px;
}

.services-content-btn-text {
    font-size: .85em;
    margin: 0;
    padding: 0;
    text-align: center;
}

.services-content-btn:hover {
    background-color: white;
    transition: .5s ease;
}

.services-content-src {
    width: 105px;
    height: 10px;
    margin-top: 150px;
    margin-left: 120px;
    color: white;
    font-size: .60em;
    font-family: arial;
    font-weight: normal;
    text-decoration: none;
    z-index: 2;
}

.license {
    color: white;
    font-family: arial;
    font-weight: normal;
    text-decoration: none;
}
