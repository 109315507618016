.strip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 30px;
    background-color: black;
}

.strip-text {
    color: white; 
    margin: 0;
    padding: 0;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
}

@media only screen and (max-width: 1140px){
    .strip-text {
        font-size: 1em;
    }    
}
