.gallery-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: calc(100vh - 567px);
    background-color: lightgrey;
}

.gallery-content-img-wrapper {
    width: 300px;
    height: 300px;
    margin: 30px;
    border: solid black 1px;
}

.gallery-content-img {
    object-fit: cover;
    object-position: center;
}
