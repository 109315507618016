.review-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: black;
    z-index: -2;
}

.review-img {
    position: absolute;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center; 
    z-index: -1;
}

.review-text {
    width: 325px;
    font-family: arial;
    font-size: 1.5em;
    -webkit-text-stroke: 1px black;
    color: white;
    text-align: center; 
}

.review-icons {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
}
