.about-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.about-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}

.about-img-description {
    font-size: 3em;
    font-family: arial;
    text-align: center;
    color: white;
    -webkit-text-stroke: 1px black;
}
